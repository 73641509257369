import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import '../pages.css';
import logo from '../../assets/images/ospitek_logo.png';
import FormContext from '../../components/Questionnaire/FormContext';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import DefaultValueContext from '../../components/Questionnaire/DefaultValueContext';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  form: {
    margin: theme.spacing(1, 0),
  },
  footer: {
    padding: theme.spacing(1, 0),
    marginTop: 'auto',
    textAlign: 'center',
  },
  logo: {
    maxHeight: '.875rem',
  },
}));

export const GlobalStyles = createGlobalStyle`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Questionnaire = ({
  id,
  type,
  procedureId,
  formFields,
  defaultValues,
  value,
  onChange,
  useQuestionnaireMutation,
  onDone,
}) => {
  const classes = useStyles();
  const [error, setError] = useState();
  const [busy, setBusy] = useState(false);
  const touched = useRef(false);

  const submitAnswers = useQuestionnaireMutation();

  const handleSubmit = useCallback(async () => {
    setBusy(true);
    setError();

    try {
      await submitAnswers({
        variables: {
          id,
          patientDateOfBirth: localStorage.getItem('patientDateOfBirth'),
          answers: JSON.stringify(value),
        },
      });
      touched.current = false;
    } catch (err) {
      setError(err);
      console.error(err);
    } finally {
      setBusy(false);
    }
  }, [id, submitAnswers, value]);

  const save = handleSubmit;
  const complete = onDone;

  const handleChanged = (...args) => {
    touched.current = true;
    onChange(...args);
  };

  useEffect(() => {
    function confirmExit(event) {
      if (touched) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure?';
      }
    }

    window.addEventListener('beforeunload', confirmExit);

    return () => window.removeEventListener('beforeunload', confirmExit);
  }, []);

  return (
    <div className={classes.wrapper}>
      <GlobalStyles />

      {error && (
        <Alert variant="filled" severity="error" className={classes.alert}>
          <FormattedMessage
            id="questionnaire.error.submission"
            defaultMessage="There was an error submitting the form. Please, try again. If the problem persists, contact the help desk staff."
          />
        </Alert>
      )}

      <form onSubmit={handleSubmit} className={classes.form}>
        <DefaultValueContext.Provider value={defaultValues}>
          <FormContext.Provider value={{ type, value, onChange: handleChanged, busy, save, complete, procedureId }}>
            {formFields}
          </FormContext.Provider>
        </DefaultValueContext.Provider>
      </form>

      <footer className={classes.footer}>
        <img src={logo} className={classes.logo} alt="Ospitek logo" />
      </footer>
    </div>
  );
};

export default Questionnaire;
